import React from 'react';

const ProductSection = ({ children, enabled, title }) => {
  return (
    <section
      className={`product-section ${enabled ? 'product-section--enabled' : ''}`}
    >
      {title && (
        <header className="product-section__header">
          <h2 className="product-section__header-title">{title}</h2>
        </header>
      )}
      <div className="product-section__content">{children}</div>
    </section>
  );
};

export default ProductSection;
