import React, { Component } from 'react';
import { graphql } from 'gatsby';

import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import ProductComponent from '../components/products/product/ProductNew';
import ProductWithItemsFragment from './fragments/ProductWithItems';
import ProductModel from '../models/Product';
import ProductGiftCardModel from '../models/ProductGiftCard';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query (
    $channelProducts: [Int]
    $id: String!
    $imageMapEntityIds: [Int]
    $subCategory: String
    $shopId: String
  ) {
    inRiverProduct(id: { eq: $id }) {
      ...ProductWithItems
    }
    imageMapInRiverProducts: allInRiverProduct(
      filter: { entityId: { in: $imageMapEntityIds } }
    ) {
      edges {
        node {
          ...Product
        }
      }
    }
    relatedInRiverProducts: allInRiverProduct(
      limit: 6
      filter: {
        entityId: { in: $channelProducts }
        id: { ne: $id }
        ProductSubCategory: { value: { value: { en: { eq: $subCategory } } } }
      }
    ) {
      edges {
        node {
          ...Product
        }
      }
    }
    yotpoProductBottomline(productIdentifier: { eq: $shopId }) {
      id
      score
      totalReviews
    }
  }
`;

class Product extends Component {
  render() {
    const { brand, menus, path, site } = this.props;

    const { siteUID } = this.props.pageContext;
    const {
      inRiverProduct,
      imageMapInRiverProducts,
      relatedInRiverProducts,
      yotpoProductBottomline,
    } = this.props.data;

    const product =
      this.props.pageContext.id === 'gift-card'
        ? new ProductGiftCardModel(siteUID, site)
        : new ProductModel(
            inRiverProduct,
            siteUID,
            site,
            yotpoProductBottomline
          );

    const imageMapProducts = mapEdgesToNodes(imageMapInRiverProducts).map(
      (product) => new ProductModel(product, siteUID, site)
    );

    const relatedProducts = mapEdgesToNodes(relatedInRiverProducts)
      .map((product) => new ProductModel(product, siteUID, site))
      .filter((product) => product.inventory.value);

    return (
      <ProductComponent
        breadcrumbs={breadcrumbs('PRODUCT', product, menus)}
        countries={this.props.pageContext.countries}
        product={product}
        productMenu={menus.productMenu}
        imageMapProducts={imageMapProducts}
        relatedProducts={relatedProducts}
        jsonLd={jsonLd(brand, site, path, product)}
        site={site}
      />
    );
  }
}

export default Product;
