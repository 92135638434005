import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Slider, { Slide } from '../../_common/Slider';

const ProductLifestylePhotos = ({ product, setHasLifestyleAssets }) => {
  const [lifestylePhotos, setLifestylePhotos] = useState([]);

  useEffect(() => {
    fetchLifestylePhotos();
  }, [product.baseShopId]);

  const fetchLifestylePhotos = async () => {
    const pickitResponse = await axios({
      method: 'GET',
      url: `/api/pickit/files/${product.series.value}`,
    }).then((res) => res.data);

    const yotpoResponse = await axios({
      method: 'GET',
      url: `/api/yotpo/albums/product/${product.baseShopId}`,
    }).then((res) => res.data);

    const merged = [...pickitResponse, ...yotpoResponse]
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .filter((img) => img.imageSrc || img.videoSrc);

    setLifestylePhotos(merged);
    merged.length > 0 && setHasLifestyleAssets(true);
  };

  if (lifestylePhotos.length === 0) return null;

  const params = {
    centeredSlides: true,
    centerInsufficientSlides: true,
    className: 'product-lifestyle-photos',
    height: 500,
    navigation: 'below',
    slidesPerView: 'auto',
    slideToClickedSlide: true,
  };

  const photoComp = (photo) => (
    <div className="product-lifestyle-photo">
      {photo.type === 'image' ? (
        <img src={photo.imageSrc} />
      ) : (
        <video autoPlay controls loop muted>
          <source src={photo.videoSrc} type="video/mp4" />
        </video>
      )}
    </div>
  );

  const single = lifestylePhotos.length === 1;

  return (
    <div className="product-lifestyle-photos__wrapper">
      {single ? (
        <div className="product-lifestyle-photos product-lifestyle-photos--single">
          {photoComp(lifestylePhotos[0])}
        </div>
      ) : (
        <Slider params={params}>
          {lifestylePhotos.map((photo, i) => (
            <Slide key={`${photo.date}-${i}`}>{photoComp(photo)}</Slide>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ProductLifestylePhotos;
