import React, { useEffect, useState } from 'react';

import ProductCTA from '../product-cta/ProductCTA';
import withCart from '../../../containers/plugins/withCart';
import { getPimValue } from '../../../utils/utils';
import { compose } from 'redux';
import { connect } from 'react-redux';

const ProductSizeChart = ({ onSizeChartRef, cart, product, site }) => {
  const [sizeChartHeader, setSizeChartHeader] = useState([]);
  const [sizeChartReverseSort, setSizeChartReverseSort] = useState(false);
  const [sizeChartRowClicked, setSizeChartRowClicked] = useState(null);
  const [sizeChartRows, setSizeChartRows] = useState([]);
  const [sizeChartSortIndex, setSizeChartSortIndex] = useState(0);

  useEffect(() => {
    resolveSizeChartColumns();
  }, []);

  useEffect(() => {
    resolveSizeChartColumns();
  }, [product?.baseShopId, cart?.lines]);

  const resolveSizeChartColumns = () => {
    const cartLines = cart?.lines?.edges?.map((line) => line.node) || [];
    const { sizeChartFields, variants } = product;

    const newSizeChartHeader = [];
    const newSizeChartRows = [];

    for (const sizeChartField of sizeChartFields) {
      for (const variant of variants) {
        if (
          getPimValue(variant[sizeChartField.value], site).indexOf(' | ') !== -1
        ) {
          sizeChartField.split = true;
          break;
        }
      }
    }

    const hasSku = sizeChartFields.find(({ value }) => value === 'sku');

    if (!hasSku) newSizeChartHeader.push({ title: 'SKU', value: 'sku' });

    for (const sizeChartField of sizeChartFields) {
      if (sizeChartField.split) {
        newSizeChartHeader.push(sizeChartField, sizeChartField);
      } else {
        newSizeChartHeader.push(sizeChartField);
      }
    }
    newSizeChartHeader.push({ title: 'Buy', value: 'buy' });

    for (const variant of variants) {
      const newSizeChartRow = [];

      if (!hasSku) newSizeChartRow.push(getPimValue(variant.sku));

      for (const sizeChartField of sizeChartFields) {
        if (sizeChartField.split) {
          newSizeChartRow.push(
            getPimValue(variant[sizeChartField.value], site).split(' | ')[0]
          );
          newSizeChartRow.push(
            getPimValue(variant[sizeChartField.value], site).split(' | ')[1]
          );
        } else {
          newSizeChartRow.push(
            getPimValue(variant[sizeChartField.value], site)
          );
        }
      }
      newSizeChartRow.push(
        <ProductCTA
          cartLine={cartLines.find(
            (line) => line.merchandise?.sku === getPimValue(variant.sku)
          )}
          mini={true}
          product={product}
          selectedVariant={variant}
          site={site}
        />
      );

      newSizeChartRows.push(newSizeChartRow);
    }

    setSizeChartHeader(newSizeChartHeader);
    setSizeChartRows(newSizeChartRows);
  };

  const onSizeChartSort = (sortIndex) => {
    setSizeChartReverseSort(
      sizeChartSortIndex === sortIndex ? !sizeChartReverseSort : false
    );
    setSizeChartSortIndex(sortIndex);
  };

  const onSizeChartRowClick = (sizeChartRow) => {
    setSizeChartRowClicked(
      sizeChartRow === sizeChartRowClicked ? null : sizeChartRow
    );
  };

  let sizeChartRowsSorted;

  if (sizeChartSortIndex === 0) {
    sizeChartRowsSorted = sizeChartRows;
  } else {
    sizeChartRowsSorted = sizeChartRows.sort((a, b) =>
      a[sizeChartSortIndex]?.localeCompare(b[sizeChartSortIndex])
    );
  }

  if (sizeChartReverseSort) sizeChartRowsSorted.reverse();

  return (
    <div className="table-wrapper" ref={(ref) => onSizeChartRef(ref)}>
      <table className="table">
        <thead>
          <tr>
            {sizeChartHeader.map((sizeChartColumn, i) => (
              <th
                key={`${sizeChartColumn.value}-${i}`}
                className={`${i === sizeChartSortIndex ? 'sort-key' : ''} ${
                  i === sizeChartSortIndex && sizeChartReverseSort
                    ? 'sort-key--reversed'
                    : ''
                }`}
                onClick={(e) => onSizeChartSort(i)}
              >
                {sizeChartColumn.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sizeChartRowsSorted.map((sizeChartRow, i) => (
            <tr
              key={`${sizeChartRow.toString()}-${i}`}
              className={`${
                sizeChartRowClicked === sizeChartRow.toString() ? 'clicked' : ''
              }`}
              onClick={(e) => onSizeChartRowClick(sizeChartRow.toString())}
            >
              {sizeChartRow.map((sizeChartColumn, i) => (
                <td key={`${sizeChartColumn?.toString()}-${i}`}>
                  {sizeChartColumn}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default compose(
  withCart,
  connect((state) => ({
    cart: state.shopService.cart,
  }))
)(ProductSizeChart);
