import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withCart from '../../../containers/plugins/withCart';
import withKlaviyo from '../../../containers/plugins/withKlaviyo';
import Buy from './Buy';
import Price from './Price';
import Notify from './Notify';
import NotOpen from './NotOpen';
import NoPrice from './NoPrice';
import Quantity from './Quantity';

const ProductCTA = (props) => {
  const onAddToCart = (product, variant, view) => {
    if (typeof window !== 'undefined') {
      window.gtag_wpromote('event', 'conversion', {
        send_to: `${process.env.GATSBY_SITE_GTAG_WPROMOTE}/VtjZCJKvtIIYENz1qfYC`,
        event_callback: (data) => console.log(data),
      });
    }

    props.klaviyo.addedToCart(product, variant, view);
  };

  const { mini, selectedVariant, site } = props;

  const available = selectedVariant?.inventory > 0;
  const classNames = [mini ? 'product-cta--mini' : ''].join(' ');

  const location = props.userLocation.location.countryCode;
  const inMarket = !props.countries ? true : props.countries.includes(location);

  return (
    <div className={`product-cta ${classNames}`}>
      {!site.shopEnabled ? (
        <NotOpen site={site} />
      ) : !selectedVariant?.price ? (
        <NoPrice selectedVariant={selectedVariant} site={site} />
      ) : available ? (
        <>
          <Price selectedVariant={selectedVariant} site={site} />
          {mini ? (
            <Quantity onAddToCart={onAddToCart} {...props} />
          ) : (
            <Buy onAddToCart={onAddToCart} available={available} {...props} />
          )}
        </>
      ) : !available && selectedVariant?.shopId ? (
        <>
          <NoPrice selectedVariant={selectedVariant} site={site} />
          <Notify selectedVariant={selectedVariant} site={site} />
        </>
      ) : (
        <NoPrice site={site} />
      )}
    </div>
  );
};

export default compose(
  withCart,
  withKlaviyo,
  connect((state) => ({
    userLocation: { ...state.userLocationService },
  }))
)(ProductCTA);
