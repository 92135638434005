import React from 'react';

import { getCmsValue } from '../../../utils/utils';

const NoPrice = ({ selectedVariant, site }) => {
  return (
    <div className="cta-prices prices--not-available">
      {!selectedVariant
        ? 'Make a selection'
        : getCmsValue(site.strings.productCTA__notAvailable, site)}
    </div>
  );
};

export default NoPrice;
