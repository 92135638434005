import React, { useRef, useState } from 'react';

import Media from '../../../models/slices/Media';
import ProductIcons from './ProductIcons';
import ProductImages from './ProductImages';
import ProductLifestylePhotos from './ProductLifestylePhotos';
import Products from '../../../models/slices/Products';
import ProductSection from './ProductSection';
import ProductSizeChart from './ProductSizeChart';
import Slice from '../../slices/Slice';
import { getPimValue } from '../../../utils/utils';

const ProductContent = ({
  header,
  onColorClick,
  product,
  relatedProducts,
  selectedVariantSku,
  setHasLifestylePhotos,
  site,
}) => {
  const sizeChartRef = useRef(null);

  const [hasIcons, setHasIcons] = useState(false);
  const [hasLifestyleAssets, setHasLifestyleAssets] = useState(false);

  return (
    <div className="product-content__wrapper">
      <div className="product-content">
        <ProductImages
          product={product}
          selectedVariantSku={selectedVariantSku}
          site={site}
        />

        {header}

        <ProductSection title="Properties" enabled={hasIcons}>
          <ProductIcons
            setHasIcons={setHasIcons}
            onClick={onColorClick}
            product={product}
            site={site}
          />
        </ProductSection>

        <ProductSection title="See it in action" enabled={hasLifestyleAssets}>
          <ProductLifestylePhotos
            product={product}
            setHasLifestyleAssets={setHasLifestyleAssets}
          />
        </ProductSection>

        <ProductSection
          title="Size Chart"
          enabled={product.sizeChartFields.length > 0}
        >
          <ProductSizeChart
            onSizeChartRef={(ref) => (sizeChartRef.current = ref)}
            product={product}
            site={site}
          />
        </ProductSection>

        <ProductSection title="Video" enabled={product.videos.length > 0}>
          <Slice
            slice={
              new Media({
                _key: 'media',
                _type: 'mediaSlice',
                media: {
                  type: 'video',
                  videoType: 'youtube',
                  videoYoutube: getPimValue(product.videos[0]),
                },
              })
            }
          />
        </ProductSection>

        <ProductSection title="Reviews" enabled={product.baseShopId}>
          <div
            className="product__reviews yotpo yotpo-main-widget"
            data-product-id={product.baseShopId}
            data-price={product.variants[0]?.price}
            data-currency="USD"
            data-name={getPimValue(product.name)}
            data-url={typeof window !== 'undefined' ? window.location.href : ''}
            data-image-url={product.images[0]?.url || ''}
          />
        </ProductSection>

        <ProductSection enabled={relatedProducts.length > 0}>
          <Slice
            slice={
              new Products(
                {
                  _key: 'products',
                  _type: 'productsSlice',
                  multiRow: false,
                  title: {
                    ...site.strings.productPage__relatedProductsTitle,
                  },
                },
                site.uid,
                { products: relatedProducts }
              )
            }
            site={site}
          />
        </ProductSection>
      </div>
    </div>
  );
};

export default ProductContent;
