import React from 'react';

import anatomyIconsMapping from './anatomy-icons-row/anatomyIconsMapping';
import Slider, { Slide } from '../../_common/Slider';
import ProductIcon from './ProductIcon';
import ProductImage from '../ProductImage';
import { getPimValue } from '../../../utils/utils';

const HooksIcons = ({ product, site }) => {
  const needlePoints = ['AlphaPoint', 'UltraPoint'];

  const icons = [];

  for (const iconKey of Object.keys(anatomyIconsMapping)) {
    const value = product[iconKey];

    if (!value?.value) continue;
    if (!anatomyIconsMapping[iconKey][value.key]) continue;

    const iconSet = anatomyIconsMapping[iconKey][value.key].icon.filter(
      (icon) => icon
    );

    if (iconSet.length === 0) continue;

    icons.push(
      ...iconSet.map((icon, i) => (
        <Slide key={`${iconKey}-${i}`}>
          <ProductIcon
            icon={icon}
            text={
              i > 0 && needlePoints.includes(value.key)
                ? 'Needle Point'
                : getPimValue(product[iconKey], site)
            }
          />
        </Slide>
      ))
    );
  }

  return icons;
};

const LuresIcons = ({ product, onClick, site }) => {
  const colorImages = {};
  for (const variant of product.variants) {
    if (colorImages[getPimValue(variant.colorName)]) continue;
    if (!variant.images[0]) continue;
    colorImages[getPimValue(variant.colorName)] = variant.images[0];
  }

  const icons = Object.keys(colorImages).map((colorImageKey) => (
    <Slide key={colorImageKey}>
      <ProductIcon
        icon={
          <ProductImage
            image={{
              alt: `${getPimValue(product.name, site)} - ${colorImageKey}`,
              url: colorImages[colorImageKey]?.url,
            }}
            sizes={[{ query: '', containerSize: '150px' }]}
          />
        }
        onClick={(e) => onClick(e, colorImageKey)}
        text={colorImageKey.split('/').join(' / ')}
      />
    </Slide>
  ));

  return icons;
};

const ProductIcons = ({ product, onClick, setHasIcons, site }) => {
  const getIcons = () => {
    if (product.category.key === 'Hooks') {
      setHasIcons(true);
      return <HooksIcons product={product} onClick={onClick} site={site} />;
    } else if (product.category.key === 'Lures') {
      setHasIcons(true);
      return <LuresIcons product={product} onClick={onClick} site={site} />;
    }
  };

  const sliderParams = {
    centerInsufficientSlides: true,
    className: 'product-icons',
    navigation: 'below',
    slidesPerView: 4,
    breakpoints: {
      560: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 5,
      },
      1024: {
        slidesPerView: 6,
      },
      1200: {
        slidesPerView: 7,
      },
      1600: {
        slidesPerView: 8,
      },
    },
  };

  return (
    <div className="product-icons__wrapper">
      <Slider params={sliderParams}>{getIcons()}</Slider>
    </div>
  );
};

export default ProductIcons;
