import React, { useState } from 'react';

import ProductCTA from '../product-cta/ProductCTA';
import ProductVariantSelector from '../ProductVariantSelector';
import SiteLoader from '../../_common/SiteLoader';
import { ArrowDown } from '../../_common/Icons';
import { getPimValue } from '../../../utils/utils';

const ProductHeader = ({
  countries,
  selectedVariant,
  setSelectedVariantSku,
  setVariantSelectorOption,
  shopProductLoading,
  product,
  site,
}) => {
  const [fullDescription, setFullDescription] = useState(false);

  return (
    <div className="product-header__wrapper">
      <div className="product-header">
        <h1 className="product__title">{getPimValue(product.name, site)}</h1>

        <div className="product__meta">
          <div className="product__meta-series">
            {getPimValue(product.series, site)}
          </div>

          {product.baseShopId && (
            <div
              className="yotpo bottomLine product__meta-rating"
              data-yotpo-product-id={product.baseShopId}
            />
          )}
        </div>

        <div
          className={`product__short-description ${
            fullDescription ? 'product__short-description--full' : ''
          }`}
        >
          <span onClick={() => setFullDescription(!fullDescription)}>
            {getPimValue(product.shortDescription)}
          </span>
        </div>

        {shopProductLoading ? (
          <SiteLoader />
        ) : (
          <>
            <ProductVariantSelector
              onLoad={(f) => (setVariantSelectorOption.current = f)}
              onVariantSelected={(sku) => setSelectedVariantSku(sku)}
              product={product}
              site={site}
              sizeChartFields={product.sizeChartFields}
              urlInteraction
            />

            <ProductCTA
              countries={countries}
              product={product}
              selectedVariant={selectedVariant}
              site={site}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductHeader;
