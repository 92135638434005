import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ProductHeader from './ProductHeader';
import ProductContent from './ProductContent';
import withKlaviyo from '../../../containers/plugins/withKlaviyo';
import withShopProduct from '../../../containers/plugins/withShopProduct';
import { getPimValue } from '../../../utils/utils';

const Product = ({
  countries,
  fetchProduct,
  klaviyo,
  product,
  relatedProducts,
  shopProductsLoading,
  site,
  yotpo,
}) => {
  const [selectedVariantSku, setSelectedVariantSku] = useState(null);
  const [yotpoLoaded, setYotpoLoaded] = useState(false);
  const [hasLifestylePhotos, setHasLifestylePhotos] = useState(false);

  const setVariantSelectorOption = useRef(null);

  useEffect(() => {
    fetchProduct();
    klaviyo?.viewedProduct(product);
  }, []);

  useEffect(() => {
    if (product.shopId && !yotpoLoaded && typeof window !== undefined) {
      try {
        window.yotpo.refreshWidgets();
      } catch (err) {}

      setYotpoLoaded(true);
    }
  }, [product, yotpoLoaded]);

  const onColorClick = (e, color) => {
    const index = product.variableFields.reduce((acc, field, i) => {
      if (field.value === 'colorName') acc = i;
      return acc;
    }, 0);

    setVariantSelectorOption.current(color, 'colorName', index);
  };

  const shopProductLoading = shopProductsLoading.includes(product.path);
  // prettier-ignore
  const selectedVariant = product.variants.find((variant) =>
    getPimValue(variant.sku, site) === selectedVariantSku
  ) || null;

  return (
    <article className="page product">
      <ProductContent
        hasLifestylePhotos={hasLifestylePhotos}
        header={
          <ProductHeader
            countries={countries}
            product={product}
            selectedVariant={selectedVariant}
            setSelectedVariantSku={setSelectedVariantSku}
            setVariantSelectorOption={setVariantSelectorOption}
            shopProductLoading={shopProductLoading}
            site={site}
          />
        }
        onColorClick={onColorClick}
        product={product}
        relatedProducts={relatedProducts}
        selectedVariantSku={selectedVariantSku}
        setHasLifestylePhotos={setHasLifestylePhotos}
        site={site}
      />
      <ProductHeader
        countries={countries}
        product={product}
        selectedVariant={selectedVariant}
        setSelectedVariantSku={setSelectedVariantSku}
        setVariantSelectorOption={setVariantSelectorOption}
        shopProductLoading={shopProductLoading}
        site={site}
      />
    </article>
  );
};

export default compose(
  withKlaviyo,
  withShopProduct,
  connect((state) => ({
    shopProductsLoading: state.shopService.shopProductsLoading,
  }))
)(Product);
