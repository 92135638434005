import React from 'react';

import { getPrice } from '../../../utils/utils';

const Price = ({ selectedVariant, site }) => {
  const oldPrice = parseFloat(selectedVariant?.oldPrice);
  const price = parseFloat(selectedVariant?.price);

  const off = (((oldPrice - price) / oldPrice) * 100).toFixed();

  return (
    <div className="cta-prices">
      {oldPrice > price ? (
        <>
          <span className="cta-price price--old">
            {getPrice(site, oldPrice)}
          </span>
          <span className="cta-price price--new">{getPrice(site, price)}</span>
        </>
      ) : (
        <span className="cta-price">{getPrice(site, price)}</span>
      )}
    </div>
  );
};

export default Price;
