import React, { useEffect, useState } from 'react';

const Quantity = ({ cartLine, selectedVariant, ...props }) => {
  const [quantity, setQuantity] = useState(cartLine?.quantity || 0);

  useEffect(() => {
    setQuantity(cartLine?.quantity || 0);
  }, [cartLine?.quantity]);

  const onQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const onQuantityBlur = () => {
    const { product } = props;

    cartLine?.quantity > 0
      ? props.updateLineItem(cartLine.id, quantity)
      : props.addLineItem(selectedVariant.shopId, quantity);

    if (quantity > 0) props.onAddToCart(product, selectedVariant, 'Table View');
  };

  const onDecrement = () => {
    cartLine?.quantity > 0
      ? props.updateLineItem(cartLine.id, cartLine.quantity - 1)
      : undefined;
  };

  const onIncrement = () => {
    const { product } = props;

    cartLine?.id
      ? props.updateLineItem(cartLine.id, cartLine.quantity + 1)
      : props.addLineItem(selectedVariant.shopId, quantity + 1);

    props.onAddToCart(product, selectedVariant, 'Table View');
  };

  const classNames = [quantity === 0 ? 'quantity--empty' : ''].join(' ');

  return (
    <div className={`cta-quantity-container ${classNames}`}>
      <button className="quantity-button" onClick={onDecrement}>
        -
      </button>
      <input
        className="quantity"
        type="number"
        onBlur={onQuantityBlur}
        onChange={onQuantityChange}
        value={quantity}
      />
      <button className="quantity-button" onClick={onIncrement}>
        +
      </button>
    </div>
  );
};

export default Quantity;
