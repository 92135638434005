import React from 'react';

import Popup from '../_common/Popup';
import ProductCTA from './product-cta/ProductCTA';
import Table from '../_common/Table';
import { getPimValue } from '../../utils/utils';

const ProductVariantSelectorTable = ({
  cart,
  onClose,
  product,
  site,
  variableFields,
  visible,
}) => {
  const cartLines = cart?.lines.edges.map((line) => line.node) || [];

  const data = {
    columns: [
      {
        title: 'Reference',
        value: 'sku',
      },
      ...variableFields.map((field) => ({
        title: field.title,
        value: field.value,
      })),
      {
        title: 'Price',
        value: 'price',
        sort: (sortKey) => (a, b) => {
          const curr = a.slice(sortKey.key)[0].props.selectedVariant;
          const next = b.slice(sortKey.key)[0].props.selectedVariant;

          if (curr.available && next.available) {
            return curr.price - next.price;
          } else if (curr.available && !next.available) {
            return 1;
          } else if (!curr.available && next.available) {
            return -1;
          }
        },
      },
    ],
    rows: product.variants.map(
      (variant) => [
        getPimValue(variant.sku),
        ...variableFields.map((field) => getPimValue(variant[field.value])),
        <ProductCTA
          cartLine={cartLines.find(
            (line) => line.merchandise?.sku === getPimValue(variant.sku)
          )}
          mini={true}
          product={product}
          selectedVariant={variant}
          site={site}
        />,
      ],
      []
    ),
  };

  return (
    <div className="product-variant-selector-table">
      <Popup inverted noHeader noPadding onClose={onClose} visible={visible}>
        <Table data={data} freezeFirstColumn freezeFirstRow />
      </Popup>
    </div>
  );
};

export default ProductVariantSelectorTable;
