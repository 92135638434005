import React, { useState } from 'react';

import { Slide } from '../../_common/Slider';

const ProductIcon = ({ icon, onClick, text }) => {
  return (
    <Slide>
      <li className="product-icon" onClick={onClick ? onClick : undefined}>
        <span className="product-icon__icon">{icon}</span>
        <span className="product-icon__text">{text}</span>
      </li>
    </Slide>
  );
};

export default ProductIcon;
