import React from 'react';

import { getCmsValue } from '../../../utils/utils';

const NotOpen = ({ site }) => {
  return (
    <div className="cta__not-yet-available">
      {getCmsValue(site.strings.productCTA__notAvailableRegion, site)}
    </div>
  );
};

export default NotOpen;
