import axios from 'axios';
import React, { useState } from 'react';

import SiteLoader from '../../_common/SiteLoader';
import { getCmsValue } from '../../../utils/utils';

const Notify = ({ selectedVariant, site }) => {
  const [email, setEmail] = useState('');
  const [marketing, setMarketing] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [response, setResponse] = useState(null);

  const onNotifyOnStockSubmit = async (e) => {
    e.preventDefault();

    const variantId = selectedVariant.shopId.split('/').slice(-1)[0];

    setIsSending(true);

    const data = {
      a: process.env.GATSBY_SITE_KLAVIYO_COMPANY_ID,
      email: email,
      variant: variantId,
      platform: 'shopify',
    };

    const response = await axios({
      method: 'POST',
      url: `/api/klaviyo/catalog/subscribe`,
      data: data,
    }).catch((err) => err);

    setIsSending(false);
    setResponse(response.data);
  };

  const responseComponent =
    response?.status === 400 ? (
      <span>{getCmsValue(site.strings.productCTA__notifyError, site)}</span>
    ) : (
      <span>{getCmsValue(site.strings.productCTA__notifySuccess, site)}</span>
    );

  const formComponent = (
    <form onSubmit={onNotifyOnStockSubmit}>
      <div className="notify-on-stock__email-wrapper">
        <input
          name="email"
          type="text"
          placeholder={getCmsValue(
            site.strings.productCTA__notifyEmailPlaceholder,
            site
          )}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <label className="notify-on-stock__marketing-wrapper">
        <input
          name="marketing"
          type="checkbox"
          onChange={(e) => setMarketing(e.target.checked)}
        />
        <span>{getCmsValue(site.strings.productCTA__notifyConsent, site)}</span>
      </label>

      <button
        className="notify-on-stock__submit-button"
        onClick={onNotifyOnStockSubmit}
      >
        {getCmsValue(site.strings.productCTA__notifyButton, site)}
      </button>
    </form>
  );

  return (
    <div className="cta__notify-on-stock">
      {isSending ? (
        <SiteLoader />
      ) : response ? (
        responseComponent
      ) : (
        formComponent
      )}
    </div>
  );
};

export default Notify;
